import { Switch } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { Offer_findSingleOffer } from "_graphql/queries/__generated__/Offer";
import { Treaty_treaty } from "_graphql/queries/__generated__/Treaty";
import clsx from "clsx";
import {
  AlertCard,
  CurrencyPicker,
  RegularSelectInput,
  RichEditor,
  SelectInput,
  TextArea,
  TextInput,
} from "components/core";
import { FormikProps } from "formik";
import numeral from "numeral";
import { FC, useEffect } from "react";
import { classNames } from "utils";

export interface TreatyClaimFormProps {
  form: FormikProps<any>;
  treaty: Treaty_treaty | null | undefined;
}

const TreatyClaimForm: FC<TreatyClaimFormProps> = ({ form, treaty }) => {


  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div className="space-y-4">
        {/* Insurer Detail section */}
        <div className="border border-gray-300 border-dashed bg-gray-100 divide-y divide-dashed divide-gray-300 rounded-md">
          <div className="grid grid-cols-4 gap-4 p-4">
            <div className="col-span-2">
              <span className="block text-sm font-light text-gray-700">
                Reinsured
              </span>
              <div className="mt-1 block w-full sm:text-sm font-medium">
                {treaty?.insurer?.insurer_company_name || "N/A"}
              </div>
            </div>
            <div className="col-span-2">
              <span className="block text-sm font-light text-gray-700">
                Reinsured Email
              </span>
              <div className="mt-1 block w-full sm:text-sm font-medium">
                {treaty?.insurer?.insurer_company_name || "N/A"}
              </div>
            </div>
          </div>
        </div>
        {/* Alert Section */}
        <AlertCard
          type="danger"
          title="Please take note"
          message={"Manual claim input allows you to input as many claim records as you want. But you are advised to use the automated input to expedite input operation and reduce human error for records greater than 10"} />
      </div>

      {/* Actual Form Section */}

      <div className="pt-3">
        <div className="grid grid-cols-2 gap-2">
          <div>
            <TextInput
              type="text"
              id="policy_number"
              label="Policy Number"
              placeholder="e.g. NCA-123-6457373"
              required={true}
              {...form}
            />
          </div>
          <div>
            <TextInput
              type="text"
              id="claim_number"
              label="Claim Number"
              placeholder="e.g. NCA-123-6457373"
              required={true}
              {...form}
            />
          </div>
          <div>
            <TextInput
              type="date"
              id="date_of_loss"
              label="Date of Loss"
              placeholder="e.g. NCA-123-6457373"
              required={true}
              {...form}
            />
          </div>
          <div>
            <TextInput
              type="text"
              id="insured"
              label="Insured"
              placeholder="e.g. Kwame Nkrumah"
              required={true}
              {...form}
            />
          </div>
          <div className="col-span-2">
            <TextInput
              type="text"
              id="claim_paid"
              label="Claim Paid"
              placeholder="e.g. 1000000"
              required={true}
              {...form}
            />
          </div>
          <div>
            <RegularSelectInput
              options={[
                {
                  label: "Bank Transfer",
                  value: "Bank Transfer",
                },
                {
                  label: "Cheque",
                  value: "Cheque",
                },
              ]}
              id="layer_number"
              label="Layer Number"
              placeholder="e.g. NCA-123-6457373"
              required={true}
              {...form}
            />
          </div>
          <div>
            <TextInput
              type="number"
              id="payment_to"
              label="Deductible"
              placeholder="e.g. 1200"
              required={true}
              {...form}
            />
          </div>
        </div>
      </div>

      <div className="pt-3">
        <span className="text-xs font-light">Claim Details</span>
        <div className="grid grid-cols-2 gap-2">
          <div>
            <TextInput
              type="number"
              step={0.01}
              id="payment_details.payment_amount"
              label="Plus(+) Third Party Claim Amount"
              placeholder="Third Party Claim Amount"
              required={true}
              {...form}
            />
          </div>
          <div>
            <TextInput
              type="number"
              step={0.01}
              id="payment_details.payment_amount"
              label="Plus(+) Third Survey fees"
              placeholder="Third Survey fees"
              required={true}
              {...form}
            />
          </div>
          <div>
            <TextInput
              type="number"
              step={0.01}
              id="payment_details.payment_amount"
              label="Plus(+) Legal fees"
              placeholder="Legal fees"
              required={true}
              {...form}
            />
          </div>
          <div>
            <TextInput
              type="number"
              step={0.01}
              id="payment_details.payment_amount"
              label="Minus(-) Salvage"
              placeholder="Salvage"
              required={true}
              {...form}
            />
          </div>
          <div>
            <TextInput
              type="number"
              step={0.01}
              id="payment_details.payment_amount"
              label="Company name"
              placeholder="Company name"
              required={true}
              {...form}
            />
          </div>
          <div>
            <TextInput
              type="number"
              step={0.01}
              id="payment_details.payment_amount"
              label="Plus(+) Net liability amount"
              placeholder="Net liability amount"
              required={true}
              {...form}
            />
          </div>

        </div>
        <div className="pt-3">
          <div className="grid grid-cols-2 gap-2">
            <div className="col-span-2">
              <RichEditor id="payment_details.payment_amount"
                label="Claim Comment"
                placeholder="Comment"
                required={true}
                {...form} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TreatyClaimForm;
