import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import React, { FC } from 'react'

type Props = {
    message: string
    title?: string
    type?: "info" | "warning" | "danger"
}

const AlertCard: FC<Props> = ({ title, message, type = "warning" }) => {

    const color = type === "warning" ? "yellow" : type === "info" ? "indigo" : "red"

    return (
        <div className={`rounded-md bg-${color}-50 p-2`}>
            <div className="flex">
                <div className="flex-shrink-0">
                    <ExclamationTriangleIcon aria-hidden="true" className={`h-5 w-5 text-${color}-400`} />
                </div>
                <div className="ml-3">
                    <h3 className={`text-sm font-medium text-${color}-800`}>{title || "Attention needed"}</h3>
                    <div className={`mt-2 text-sm text-${color}-700`}>
                        <p>
                            {message}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AlertCard