import { gql } from "@apollo/client";


export const getTreatyClaims = gql`
query GetTreatyClaims($treatyId: ID) {
  getTreatyClaimsCount(treaty_id: $treatyId)
  getTreatyClaims(treaty_id: $treatyId) {
    treaty_claim_id
    policy_number
    claim_number
    insured_name
    date_of_loss
    claim_date
    claim_paid
    layer_number
    expected_deductible
    treaty_comment
    treaty_claim_details
    reinstatement_premium
    treaty {
      treaty_id
      treaty_reference
      kek_reference
      treaty_details
      currency
      order_hereon
      treaty_payment_status
      layer_limit
    }
  }
}
`;