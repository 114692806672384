import { Outlet, Route } from "react-location";
import {
  DashboardPage,
  SignInPage,
  EmployeesPage,
  InsurersPage,
  InsurerPage,
  ReInsurersPage,
  ReInsurerPage,
  BrokersPage,
  BrokerPage,
  CreateSlipPage,
  CreateClosingPage,
  InsurerPaymentsPage,
  ClassOfBusinessPage,
  ClaimsPage,
  OfferPage,
  CreditNotesPage,
  ReInsurerPaymentsPage,
  TreatyProgramsPage,
  ViewClaimsPage,
  ReportsPage,
  TreatiesPage,
  ResetPasswordPage,
  ViewTreatyPage,
  EndorsementsPage,
  EndorsementPage,
  TreatyProgramDeductionsPage,
  ViewClaimSharesPage,
  ManageTreatyPage,
  NotificationsPage,
  ClaimSharePaymentsPage,
  TreatyClaimsPage,
  ViewTreatyClaimsPage
} from "pages";
import { UserPermission } from "_graphql/cache/auth";
import {
  ArchiveBoxIcon,
  BuildingOffice2Icon,
  ChartBarSquareIcon,
  DocumentDuplicateIcon,
  IdentificationIcon,
  LockClosedIcon,
  NewspaperIcon,
  RectangleStackIcon,
  UserGroupIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { DocumentPlusIcon } from "@heroicons/react/24/solid";

export type RouteProps = Omit<Route, "children"> & {
  withRoles?: UserPermission[];
  navigation?: boolean;
  sidebar?: { label: string; icon: any };
  children?: RouteProps[];
};

const routes: RouteProps[] = [
  {
    path: "/",
    element: <Outlet />,
    sidebar: {
      label: "Dashboard",
      icon: ChartBarSquareIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Dashboard",
      section: "General",
    },
    children: [
      {
        id: "all",
        path: "/",
        element: <DashboardPage />,
        meta: {
          layout: "App",
          section: "General",
        },
      },
    ],
  },
  {
    path: "create-slip",
    element: <Outlet />,
    meta: {
      layout: "App",
      breadcrumb: () => "Create Slip",
      section: "Facultative Businesses",
    },
    sidebar: {
      label: "Create Slip",
      icon: DocumentPlusIcon,
    },
    withRoles: [
      "Broking Officer",
      "Senior Broking Officer",
      "Managing Director",
      "Finance Officer",
      "Frontline Executive",
      "System Administrator",
    ],
    children: [
      {
        id: "all",
        path: "/",
        element: <CreateSlipPage />,
        meta: {
          layout: "App",
          section: "General",
        },
        children: [
          {
            path: ":offer/view",
            element: <OfferPage />,
            meta: {
              layout: "App",
              section: "General",
            },
          },
        ],
      },
      {
        path: ":offer/view",
        element: <OfferPage />,
        meta: {
          layout: "App",
          section: "General",
          breadcrumb: () => "View Slip",
        },
      },
    ],
  },
  {
    path: "create-closing",
    element: <Outlet />,
    withRoles: [
      "Broking Officer",
      "Senior Broking Officer",
      "Managing Director",
      "Finance Officer",
      "Frontline Executive",
      "System Administrator",
    ],
    meta: {
      layout: "App",
      breadcrumb: () => "Create Closing",
      section: "Facultative Businesses",
    },
    sidebar: {
      label: "Create Closing",
      icon: LockClosedIcon,
    },
    children: [
      {
        id: "all",
        path: "/",
        element: <CreateClosingPage />,
        meta: {
          layout: "App",
          section: "General",
        },
        children: [],
      },
      {
        path: ":offer/credit-notes",
        element: <CreditNotesPage />,
        meta: {
          layout: "App",
          section: "General",
          breadcrumb: () => "View Credit Notes",
        },
      },
    ],
  },
  {
    path: "endorsements",
    element: <Outlet />,
    withRoles: [
      "Broking Officer",
      "Senior Broking Officer",
      "Managing Director",
    ],
    meta: {
      layout: "App",
      breadcrumb: () => "Endorsements",
      section: "Facultative Businesses",
    },
    sidebar: {
      label: "Endorsements",
      icon: LockClosedIcon,
    },
    children: [
      {
        path: "/",
        element: <EndorsementsPage />,
        meta: {
          layout: "App",
          section: "Facultative Businesses",
        },
        children: [],
      },
      {
        path: ":endorsement/endorsement/:endorsement_policy_number",
        element: <EndorsementPage />,
        meta: {
          layout: "App",
          section: "Facultative Businesses",
          breadcrumb: ({
            endorsement_policy_number,
          }: {
            endorsement_policy_number: string;
          }) => endorsement_policy_number,
        },
        children: [],
      },
    ],
  },
  {
    path: "insurers",
    withRoles: [
      "Broking Officer",
      "Senior Broking Officer",
      "Managing Director",
      "Finance Officer",
      "Frontline Executive",
      "System Administrator",
    ],
    element: <Outlet />,
    meta: {
      layout: "App",
      breadcrumb: () => "Insurers",
      section: "Clients",
    },
    sidebar: {
      label: "Insurers",
      icon: UsersIcon,
    },
    children: [
      {
        path: "/",
        element: <InsurersPage />,
        meta: {
          layout: "App",
          section: "Clients",
        },
        children: [],
      },
      {
        path: ":insurer/view",
        element: <Outlet />,
        meta: {
          layout: "App",
          section: "Clients",
          breadcrumb: () => "View Insurer",
        },
        children: [
          {
            path: "/",
            element: <InsurerPage />,
            meta: {
              layout: "App",
              section: "Clients",
            },
          },
          {
            path: ":offer/payments",
            element: <Outlet />,
            meta: {
              layout: "App",
              section: "Clients",
              breadcrumb: () => "View Payments",
            },
            children: [
              {
                path: "/",
                element: <InsurerPaymentsPage />,
                meta: {
                  layout: "App",
                  section: "Clients",
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "re-insurers",
    element: <Outlet />,
    withRoles: [
      "Broking Officer",
      "Senior Broking Officer",
      "Managing Director",
      "Finance Officer",
      "Frontline Executive",
      "System Administrator",
    ],
    meta: {
      layout: "App",
      breadcrumb: () => "Re-Insurers",
      section: "Clients",
    },
    sidebar: {
      label: "Re-Insurers",
      icon: UserGroupIcon,
    },
    children: [
      {
        path: "/",
        element: <ReInsurersPage />,
        meta: {
          layout: "App",
          section: "General",
        },
      },
      {
        path: ":reInsurer",
        element: <Outlet />,
        meta: {
          layout: "App",
          section: "General",
          breadcrumb: () => "View Re-Insurer",
        },
        children: [
          {
            path: "/",
            element: <ReInsurerPage />,
            meta: {
              layout: "App",
              section: "Clients",
            },
          },
          {
            path: ":offer_participant/payments/:offer",
            element: <ReInsurerPaymentsPage />,
            meta: {
              layout: "App",
              section: "Clients",
              breadcrumb: () => "View Re-Insurer Payments",
            },
          },
        ],
      },
    ],
  },
  {
    path: "brokers",
    element: <Outlet />,
    meta: {
      layout: "App",
      breadcrumb: () => "Reinsurance Brokers",
      section: "Clients",
    },
    sidebar: {
      label: "Reinsurance Brokers",
      icon: BuildingOffice2Icon,
    },
    children: [
      {
        path: "/",
        element: <BrokersPage />,
        meta: {
          layout: "App",
          section: "General",
        },
      },
      {
        path: ":broker",
        element: <Outlet />,
        meta: {
          layout: "App",
          section: "General",
          breadcrumb: () => "View Broker",
        },
        children: [
          {
            path: "/",
            element: <BrokerPage />,
            meta: {
              layout: "App",
              section: "General",
            },
          },
          {
            path: ":treaty/view",
            element: <Outlet />,
            meta: {
              layout: "App",
              section: "General",
              breadcrumb: () => "View Treaty",
            },
            children: [
              {
                path: "/",
                element: <ViewTreatyPage />,
                meta: {
                  layout: "App",
                  section: "General",
                  // breadcrumb: () => "View Treaty",
                },
              },
              {
                path: ":ref/manage-treaty",
                element: <ManageTreatyPage />,
                meta: {
                  layout: "App",
                  section: "General",
                  breadcrumb: ({ ref }: any) => `Manage Treaty - ${ref?.replaceAll("__", "/")}`,
                },
              }
            ],
          },
        ]
      },
    ],
    withRoles: [
      "Broking Officer",
      "Senior Broking Officer",
      "Managing Director",
      "Finance Officer",
      "Frontline Executive",
      "System Administrator",
    ],
  },
  {
    path: "class-of-business",
    element: <Outlet />,
    meta: {
      layout: "App",
      breadcrumb: () => "Setup Business",
      section: "Facultative Businesses",
    },
    sidebar: {
      label: "Setup Business",
      icon: DocumentDuplicateIcon,
    },
    children: [
      {
        id: "all",
        path: "/",
        element: <ClassOfBusinessPage />,
        meta: {
          layout: "App",
          section: "General",
        },
      },
    ],
    withRoles: [
      "Broking Officer",
      "Senior Broking Officer",
      "Managing Director",
    ],
  },
  {
    path: "claims",
    element: <Outlet />,
    meta: {
      layout: "App",
      breadcrumb: () => "Facultative Claims",
      section: "Facultative Businesses",
    },
    sidebar: {
      label: "Facultative Claims",
      icon: NewspaperIcon,
    },
    children: [
      {
        id: "all",
        path: "/",
        element: <ClaimsPage />,
        meta: {
          layout: "App",
          section: "Facultative Businesses",
        },
      },
      {
        id: "all-claims",
        path: "/:offer/view",
        element: <Outlet />,
        meta: {
          layout: "App",
          section: "General",
          breadcrumb: () => "View Claims",
        },
        children: [
          {
            path: "/",
            element: <ViewClaimsPage />,
            meta: {
              layout: "App",
              section: "General",
              // breadcrumb: () => "View Shares",
            },
          },
          {
            id: "all-claims-claim",
            path: ":claim/view",
            element: <Outlet />,
            meta: {
              layout: "App",
              section: "General",
              breadcrumb: () => "View Shares",
            },
            children: [
              {
                path: "/",
                element: <ViewClaimSharesPage />,
                meta: {
                  layout: "App",
                  section: "General",
                  // breadcrumb: () => "View Shares",
                },
              },
              {
                path: ":share/payments",
                element: <ClaimSharePaymentsPage />,
                meta: {
                  layout: "App",
                  section: "General",
                  breadcrumb: () => "View Share Payments",
                },
              },
            ],
          },
        ],
      },
    ],
    withRoles: [
      "Broking Officer",
      "Senior Broking Officer",
      "Managing Director",
      "Finance Officer",
      "Frontline Executive",
      "System Administrator",
    ],
  },
  {
    path: "employees",
    element: <Outlet />,
    meta: {
      layout: "App",
      breadcrumb: () => "Employees",
      section: "Others",
    },
    sidebar: {
      label: "Employees",
      icon: IdentificationIcon,
    },
    children: [
      {
        id: "all",
        path: "/",
        element: <EmployeesPage />,
        meta: {
          layout: "App",
          section: "General",
        },
      },
    ],
    withRoles: [
      "Managing Director",
      "Senior Broking Officer",
      "System Administrator",
    ],
  },
  {
    path: "reports",
    element: <Outlet />,
    meta: {
      layout: "App",
      breadcrumb: () => "Reports",
      section: "Others",
    },
    sidebar: {
      label: "Reports",
      icon: ArchiveBoxIcon,
    },
    children: [
      {
        id: "all",
        path: "/",
        element: <ReportsPage />,
        meta: {
          layout: "App",
          section: "General",
        },
      },
    ],
  },
  {
    path: "treaty-programs",
    element: <Outlet />,
    meta: {
      layout: "App",
      breadcrumb: () => "Setup Treaty program",
      section: "Treaty",
    },
    sidebar: {
      label: "Setup Treaty Program",
      icon: RectangleStackIcon,
    },
    children: [
      {
        path: "/",
        element: <TreatyProgramsPage />,
        meta: {
          layout: "App",
          section: "General",
        },
      },
      {
        path: ":treaty_program/view",
        element: <TreatyProgramDeductionsPage />,
        meta: {
          layout: "App",
          breadcrumb: () => "Treaty Program Deductions",
          section: "General",
        },
      },
    ],
  },
  {
    path: "treaties",
    element: <Outlet />,
    meta: {
      layout: "App",
      breadcrumb: () => "Treaties",
      section: "Treaty",
    },
    sidebar: {
      label: "Treaties",
      icon: DocumentDuplicateIcon,
    },
    children: [
      {
        path: "/",
        element: <TreatiesPage />,
        meta: {
          layout: "App",
          section: "General",
        },
      },
      {
        path: ":treaty/view",
        element: <Outlet />,
        meta: {
          layout: "App",
          section: "General",
          breadcrumb: () => "View Treaty",
        },
        children: [
          {
            path: "/",
            element: <ViewTreatyPage />,
            meta: {
              layout: "App",
              section: "General",
              // breadcrumb: () => "View Treaty",
            },
          },
          {
            path: ":ref/manage-treaty",
            element: <ManageTreatyPage />,
            meta: {
              layout: "App",
              section: "General",
              breadcrumb: ({ ref }: any) => `Manage Treaty - ${ref?.replaceAll("__", "/")}`,
            },
          }
        ],
      },
    ],
  },
  {
    path: "treaty-claims",
    element: <Outlet />,
    meta: {
      layout: "App",
      breadcrumb: () => "Treaty Claims",
      section: "Treaty",
    },
    sidebar: {
      label: "Treaty Claims",
      icon: ArchiveBoxIcon,
    },
    children: [
      {
        path: "/",
        element: <TreatyClaimsPage />,
        meta: {
          layout: "App",
          section: "General",
        },
      },
      {
        path: ":treaty/view-claims",
        element: <ViewTreatyClaimsPage />,
        meta: {
          layout: "App",
          section: "General",
          breadcrumb: () => "View Treaty",
        },
      },
    ],
  },
  {
    path: "notifications",
    element: <Outlet />,
    meta: {
      layout: "App",
      breadcrumb: () => "System Notifications",
      section: "Notifcations",
    },
    sidebar: {
      label: "System Notifications",
      icon: RectangleStackIcon,
    },
    children: [
      {
        path: "/",
        element: <NotificationsPage />,
        meta: {
          layout: "App",
          section: "General",
        },
      },
      // {
      //   path: ":treaty_program/view",
      //   element: <TreatyProgramDeductionsPage />,
      //   meta: {
      //     layout: "App",
      //     breadcrumb: () => "Treaty Program Deductions",
      //     section: "General",
      //   },
      // },
    ],
  },
  {
    path: "cpanel-login",
    element: <SignInPage />,
    meta: {
      layout: "Auth",
      breadcrumb: () => "Auth",
    },
  },
  {
    path: "cpanel-reset-password",
    element: <ResetPasswordPage />,
    meta: {
      layout: "Auth",
      breadcrumb: () => "Auth",
    },
  },
];

export default routes;
