import { useAppLocation } from '_graphql/cache/auth'
import { OfficeHeader } from 'components'
import { useTreaty } from 'hooks/data/use-treaties'
import React, { FC } from 'react'
import { Link } from 'react-location'
import { classNames, useUrlState } from 'utils'
import ClaimsPage from './tabs/claims'
import TreatyReinsurers from './tabs/reinsurers'

type Props = {}

const tabs = [
    { name: "Claims", href: "claims" },
];

const ViewTreatyClaimsPage: FC<Props> = (props) => {
    const { params } = useAppLocation()
    const [activeTab] = useUrlState("activeTab");
    const { treaty, loading } = useTreaty({
        treatyId: params?.treaty,
    })
    return (
        <main className="flex-1 flex flex-col overflow-hidden bg-shade-500">
            {/* Header Section */}
            <OfficeHeader />
            {/* Tabs Section */}
            <div className="block">
                <div className="border-b border-shade-500 bg-card-500 px-6">
                    <nav className="-mb-px flex space-x-8 items-center" aria-label="Tabs">
                        <Link
                            search={(old) => ({
                                ...old,
                                activeTab: undefined,
                            })}
                            className={classNames(
                                !activeTab
                                    ? "border-gray-200 text-gray-200"
                                    : "border-transparent text-gray-500 hover:text-gray-300 hover:border-gray-200",
                                "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm"
                            )}
                            aria-current={!activeTab ? "page" : undefined}
                        >
                            Reinsurers
                        </Link>
                        {tabs.map((_activeTab) => (
                            <Link
                                key={_activeTab.name}
                                search={(old) => ({
                                    ...old,
                                    activeTab: _activeTab.href,
                                })}
                                className={classNames(
                                    activeTab === _activeTab.href
                                        ? "border-gray-200 text-gray-200"
                                        : "border-transparent text-gray-500 hover:text-gray-300 hover:border-gray-200",
                                    "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm"
                                )}
                                aria-current={
                                    activeTab === _activeTab.href ? "page" : undefined
                                }
                            >
                                {_activeTab.name}
                            </Link>
                        ))}

                        {/* <div className="h-6 w-px bg-gray-300" /> */}
                    </nav>
                </div>
            </div>

            {/* Tabs Container Section */}
            <div className="flex flex-1 overflow-y-auto">
                <div className="flex-1 mx-auto min-w-0 overflow-hidden overflow-y-auto light flex">
                    {!activeTab && <TreatyReinsurers />}
                    {activeTab === "claims" && <ClaimsPage />}
                </div>
            </div>
        </main>
    )
}

export default ViewTreatyClaimsPage