import { useAppLocation, useAppNavigate, useAppSearch } from "_graphql/cache/auth";
import { GetTreatyReinsurerParticipants_getTreatyReinsurerParticipants } from "_graphql/queries/__generated__/GetTreatyReinsurerParticipants";
import { Avatar, Show, TableComponent } from "components";
import ActionButton, { Action } from "components/buttons/action-button";
import { useTreaty } from "hooks/data/use-treaties";
import _ from "lodash";
import { FC } from "react";
import { Link } from "react-location";
import { classNames, useUrlState } from "utils";

type ClaimsPageProps = {};

const ClaimsPage: FC<ClaimsPageProps> = () => {
    const [modal, setModal] = useUrlState("modal");
    const [activeTab] = useUrlState("layer");
    const { params } = useAppLocation();
    const searchParams = useAppSearch();
    const navigate = useAppNavigate();


    const { treaty, loading: featchingTreaty } = useTreaty({
        treatyId: params.treaty || "",
    })

    const dispatchAction =
        (id: string, action: Exclude<Action, "expand" | "goto">) =>
            () => {
                navigate({
                    search: (old) => ({
                        ...old,
                        id,
                        modal: action,
                    }),
                });
            };

    return (
        <div className=' flex flex-col flex-1'>
            <Show if={searchParams?.treaty_type === "NONPROPORTIONAL"}>
                <div className="block">
                    <div className="border-b border-shade-500 bg-card-500 px-6">
                        <nav className="-mb-px flex space-x-8 items-center" aria-label="Tabs">
                            {JSON.parse(treaty?.layer_limit || "[]").map((_activeTab: any, index: number) => (
                                <Link
                                    key={_activeTab.name}
                                    search={(old) => ({
                                        ...old,
                                        layer: (index + 1).toString(),
                                    })}
                                    className={classNames(
                                        activeTab === index + 1
                                            ? "border-gray-200 text-gray-300"
                                            : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                                        "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm"
                                    )}
                                    aria-current={
                                        activeTab === index + 1 ? "page" : undefined
                                    }
                                >
                                    Layer {index + 1}
                                </Link>
                            ))}
                            {/* <div className="h-6 w-px bg-gray-300" /> */}

                        </nav>
                    </div>
                </div>
            </Show>
            <div className="grid grid-cols-3 sm:py-6 items-start px-3 flex-1">
                <div className='col-span-3'>
                    <TableComponent
                        title={'reinsurers'}
                        hasSearch
                        loading={featchingTreaty}
                        columns={[
                            {
                                accessor: "reinsurer_representative.reinsurer.re_company_name",
                                name: "Company Name",
                                render: (row: GetTreatyReinsurerParticipants_getTreatyReinsurerParticipants) => (
                                    <div className='flex space-x-2 items-start'>
                                        <Avatar alt={row?.reinsurer?.re_company_name || ""} />
                                        <div className='flex flex-col'>
                                            <span className='font-bold text-xs'>{row?.reinsurer?.re_company_name}</span>
                                            <span className='font-light text-xs'>{row?.reinsurer?.re_company_website}</span>
                                        </div>
                                    </div>
                                )
                            },
                            {
                                accessor: "reinsurer_representative.rep_first_name",
                                name: "Company Email",
                                render: (row: GetTreatyReinsurerParticipants_getTreatyReinsurerParticipants) => (
                                    <div className='flex flex-col'>
                                        <span className='font-bold text-xs'>{row?.reinsurer?.re_company_email}</span>
                                    </div>
                                )
                            },
                            {
                                accessor: "treaty_participation_percentage",
                                name: "Participation (%)",
                                render: (row: GetTreatyReinsurerParticipants_getTreatyReinsurerParticipants) => (
                                    <div className='flex flex-col'>
                                        <span className='font-bold text-xs'>{row?.treaty_participation_percentage ?? "0"}</span>
                                    </div>
                                )
                            },
                            {
                                accessor: "",
                                name: "Action",
                                render: (row: GetTreatyReinsurerParticipants_getTreatyReinsurerParticipants) => (
                                    <ActionButton action={'view'} tooltip={"View Treaty Closings"} onClick={dispatchAction(row?.treaty_participation_id || "", "clone")} />
                                )
                            }
                        ]}
                        data={[]}
                        // renderLoader={() => <TableLoader items={[1, 1, 1, 1]} />}
                        refetch={() => { }} />
                </div>

            </div>
        </div>

    )
};

export default ClaimsPage;
